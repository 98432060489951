.card-title {
    color: black;
    text-align: left;
}

.card {
    overflow-x: auto;
}

.subMenuLink .arrowIconSubmenu::before,
.subMenuLink.collapsed .arrowIconSubmenu::before,
.subMenuLink .arrowIconSubmenu::after {
    content: '';
    position: absolute;
    background: 0 0;
    background-image: linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255));
    border-radius: 2px;
    width: 6px;
    height: 1.5px;
    top: 4px;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform: rotate(-135deg) translateX(2px);
    -webkit-transform: rotate(-135deg) translateX(2px);
    right: -19px;
}

.subMenuLink .arrowIconSubmenu::after,
.subMenuLink.collapsed .arrowIconSubmenu::after {
    transform: rotate(-45deg) translateX(2px);
    -webkit-transform: rotate(-45deg) translateX(2px);
}


.subMenuLink:not(.collapsed) .arrowIconSubmenu::before {
    transform: rotate(-225deg) translateX(2px);
    -webkit-transform: rotate(-225deg) translateX(2px);
}

.subMenuLink:not(.collapsed) .arrowIconSubmenu::after {
    transform: rotate(45deg) translateX(2px);
    -webkit-transform: rotate(45deg) translateX(2px);
}

.subMenuLink:not(.collapsed) {
    color: #fff;
}

.subMenuLink:not(.collapsed) svg path {
    fill: #fff;
}

.custom_table .table> :not(caption)>*>* {
    text-align: center;
}



@import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap');


.loader-wrapper {
    background: rgba(255, 255, 255, 0.84);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
}

.loader {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}

.loader:before {
    content: "";
    width: 100px;
    height: 100px;
    border: 5px solid rgba(0, 0, 0, 0.05);
    border-top-color: var(--bg-primary);
    border-radius: 50%;
    animation: spin 1.5s infinite linear;
    display: block;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader img {
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

body {

    /*   background-image: url("https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/homeExchangeBg.png"); */
    font-family: Inter;
}

* {
    box-sizing: border-box;
}


body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.kBPyVG {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.cKHfpx {
    display: flex;
}

.kfJPwG {
    position: relative;
    font-family: Montserrat, sans-serif;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.66px;
    justify-content: space-between;
    background: rgb(221, 225, 231);
    color: rgb(255, 255, 255);
    border-bottom: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 5px;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
}

.bJSTZQ {
    width: 100%;
    display: flex;
    background: rgb(255, 255, 255);
    border-radius: 0px 0px 20px;
    font-size: 13px;
    justify-content: space-evenly;
    padding-right: 0px;

    position: relative;
}

.bJSTZQ {
    border-bottom: 2px solid black;
    margin-bottom: 0px !important;
}



.iILsgX {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 99;
    cursor: pointer;
}

.foOcVP {
    height: auto;
    background: rgb(0, 0, 0);
    width: 44%;
    line-height: 35px;
    position: relative;
    color: black !important;
}

.foOcVP:after {
    width: 50px;
    background: rgb(0, 0, 0);
    position: absolute;
    height: 100%;
    content: " ";
    right: -22px;
    top: 0px;
    transform: skew(45deg);
}

.eTlYgY {
    display: flex;
    padding: 0.5em;
}

.bbKLbo {
    flex-basis: 65%;
    justify-content: center;
    position: relative;
    min-width: 0px;
    padding: 0px;
}

.fNrpGt {
    display: flex;
}

.grQHZb {
    flex-basis: 80%;
    border-right: 1px solid rgba(128, 128, 128, 0.2);
    padding-left: 2em;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.fbPdEN {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25em;

    flex-shrink: 0;
}

.kjYtWL {
    display: flex;
    align-items: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 12px;
    padding-left: 20px;
}

.ddDXjD {
    width: 16.01%;
    height: 40px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.07em;
    text-transform: capitalize;
    font-size: 13px;
    margin-right: 1px;
    color: rgb(3, 117, 204);
    margin-left: 69px;
}

.efEdVc {
    position: absolute;
    inset: 0px;
    z-index: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: rgb(93, 34, 99);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    background-color: rgba(175, 174, 174, 0.455);
}

#tvBtn {
    color: #000 !important;
    font-weight: bolder;

}

#tvBtn i {
    color: #000 !important;
}

.jbTwHB {
    width: 16.01%;
    height: 40px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.07em;
    text-transform: capitalize;
    font-size: 13px;
    margin-left: 1px;
    color: rgb(224, 60, 60);
}

.jwPIDK {
    display: flex;
    width: 100%;
}

.hoLQnR {
    display: flex;
    width: 100%;
}

button {
    outline: 0;
}

.bCKsOU {
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    padding: 0px;
    flex-shrink: 0;
    font-size: 14px;
    margin: 0.2em 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.15s ease-in;
}

.bCKsOU:hover {
    opacity: 0.85;
    transition: opacity 0.25s ease-out;
}

.iAODgt {
    margin-left: 10px;
}

.hEgtIY {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
}

.hOlWHp {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    margin-top: 0.25em;
}

.bzYjxs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55px;
    flex-grow: 1;
    padding: 0.12em 0.5em;
    margin: 0px 0.15em;
    position: relative;
    z-index: 0;
    text-align: center;
    min-height: 42px;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
    background-color: rgb(163, 163, 163);
}

@media only screen and (max-width: 1200px) {
    .bzYjxs {
        width: 45px;
    }
}

.QtCGw {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55px;
    flex-grow: 1;
    padding: 0.12em 0.5em;
    border-right: 2px solid black;
    position: relative;
    z-index: 0;
    text-align: center;
    min-height: 42px;
    cursor: pointer;
    background-color: rgb(130, 207, 255);
}

@media only screen and (max-width: 1200px) {
    .QtCGw {
        width: 45px;
    }
}

.QtCGw:hover {
    box-shadow: rgb(25, 161, 239) 0px 0px 0px 3px inset;
    transition: 0.5s;
}

.fvpuXi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55px;
    flex-grow: 1;
    padding: 0.12em 0.5em;

    position: relative;
    z-index: 0;
    text-align: center;
    min-height: 42px;
    border-right: 2px solid black;
    cursor: pointer;
    background-color: rgb(255, 181, 189);
}

@media only screen and (max-width: 1200px) {
    .fvpuXi {
        width: 45px;
    }
}

.fvpuXi:hover {
    box-shadow: rgb(181, 88, 108) 0px 0px 0px 3px inset;
    transition: 0.5s;
}

.beotnh {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55px;
    flex-grow: 1;
    padding: 0.12em 0.5em;
    margin: 0px 0.15em;
    position: relative;
    z-index: 0;
    text-align: center;
    min-height: 42px;
    cursor: pointer;
    pointer-events: none;
    opacity: 0.5;
    background-color: rgb(163, 163, 163);
}

@media only screen and (max-width: 1200px) {
    .beotnh {
        width: 45px;
    }
}

.beotnh:hover {
    box-shadow: rgb(25, 161, 239) 0px 0px 0px 3px inset;
    transition: 0.5s;
}

.jOSKLT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55px;
    flex-grow: 1;
    padding: 0.12em 0.5em;
    margin: 0px 0.15em;
    position: relative;
    z-index: 0;
    text-align: center;
    min-height: 42px;
    cursor: pointer;
    pointer-events: none;
    opacity: 0.5;
    background-color: rgb(163, 163, 163);
}

@media only screen and (max-width: 1200px) {
    .jOSKLT {
        width: 45px;
    }
}

.jOSKLT:hover {
    box-shadow: rgb(181, 88, 108) 0px 0px 0px 3px inset;
    transition: 0.5s;
}

.iILsgX img {
    width: 20px;
    height: 20px;
}

.hEgtIY h1 {
    text-transform: lowercase;
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
}

.hEgtIY h1::first-letter {
    text-transform: capitalize;
}

.jtgJVn {
    font-size: 15px;
    color: rgb(31, 31, 31);
    text-align: center;
    margin-bottom: 0.1em;
    font-weight: bold;
}

@media only screen and (max-width: 1200px) {
    .jtgJVn {
        font-size: 13px;
    }
}

.gNLGlZ {
    font-size: 10px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    color: rgb(36, 38, 41);
}

.kjYtWL span {
    color: rgb(255, 255, 255);
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-Zo2Ej.style-Zo2Ej {
    color: transparent;
    display: inline-block;
    height: 100%;
    width: 100%;
}

#style-EgvzT.style-EgvzT {
    color: transparent;
    display: inline-block;
    height: 100%;
    width: 100%;
}

h1 {
    color: black;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.card-header {
    padding: 2px 5px;
}

.card-body {
    padding: 0px;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #7983ff;


    animation: spin 1s linear infinite;
}