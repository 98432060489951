@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i&display=swap');
@font-face {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/webfonts/fa-regular-400.eot);
    src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/webfonts/fa-regular-400.eot?#iefix) format("embedded-opentype"), url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/webfonts/fa-regular-400.woff2) format("woff2"), url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/webfonts/fa-regular-400.woff) format("woff"), url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/webfonts/fa-regular-400.ttf) format("truetype"), url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/webfonts/fa-regular-400.svg#fontawesome) format("svg");
}
@font-face {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/webfonts/fa-solid-900.eot);
    src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/webfonts/fa-solid-900.eot?#iefix) format("embedded-opentype"), url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/webfonts/fa-solid-900.woff2) format("woff2"), url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/webfonts/fa-solid-900.woff) format("woff"), url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/webfonts/fa-solid-900.ttf) format("truetype"), url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/webfonts/fa-solid-900.svg#fontawesome) format("svg");
}
body[data-v-8212892e] {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-family: var(--bs-body-font-family);
    font-size: 1rem;
    font-size: var(--bs-body-font-size);
    font-weight: 400;
    font-weight: var(--bs-body-font-weight);
    line-height: 1.5;
    line-height: var(--bs-body-line-height);
    color: #212529;
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    cursor: default;
}
*[data-v-8212892e] {
    box-sizing: border-box;
}
body[data-v-8212892e] {
    margin: 0;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-family: var(--bs-body-font-family);
    font-size: 1rem;
    font-size: var(--bs-body-font-size);
    font-weight: 400;
    font-weight: var(--bs-body-font-weight);
    line-height: 1.5;
    line-height: var(--bs-body-line-height);
    color: #212529;
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: #fff;
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}
body[data-v-8212892e] {
    background-color: #ffffff;
    background-color: var(--bg-body);
    color: #000000;
    color: var(--text-body);
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 400;
    font-size: 16px;
    cursor: default;
}
[data-v-8212892e]:root {
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
}
[data-v-8212892e]:root {
    --bg-primary: #000000;
    --bg-primary90: #000000E6;
    --text-primary: #FFFFFF;
    --bg-secondary: #FFC629;
    --bg-secondary70: #FFC629B3;
    --bg-secondary85: #FFC629D9;
    --text-secondary: #000000;
}
@media (prefers-reduced-motion: no-preference) {
[data-v-8212892e]:root {
        scroll-behavior: smooth;
}
}
[data-v-8212892e]:root {
    --bg-primary: #0088cc;
    --text-primary: #fff;
    --bg-secondary: #2c3e50;
}
[data-v-8212892e]:root {
    --bg-body: #ffffff;
    --text-body: #000000;
    --input-border: #dbdbdb;
}
[data-v-8212892e]:root {
    --bg-primary: #000000;
    --text-primary: #FFFFFF;
    --bg-secondary: #FFC629;
}
[data-v-8212892e]:root {
    --PhoneInput-color--focus: #03b2cb;
    --PhoneInputCountrySelect-marginRight: 0.35em;
    --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
    --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
}
.login-page[data-v-8212892e] {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(#000000, #FFC629);
    background-image: linear-gradient(var(--bg-primary), var(--bg-secondary));
}
.login-page[data-v-8212892e] {
    justify-content: center;
    margin-top: -50px;
}
*[data-v-8212892e],[data-v-8212892e]:after,[data-v-8212892e]:before {
    box-sizing: border-box;
}
.login-box[data-v-8212892e] {
    width: 350px;
    max-width: 90%;
    text-align: center;
    margin: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.login-box[data-v-8212892e] {
    margin: 2% 0;
}
.mt-4[data-v-8212892e] {
    margin-top: 1.5rem !important;
}
.login-form[data-v-8212892e] {
    padding: 20px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 0 5px #fff;
}
a[data-v-8212892e] {
    color: #0d6efd;
    text-decoration: underline;
}
a[data-v-8212892e] {
    text-decoration: none;
}
a[data-v-8212892e]:hover {
    color: #0a58ca;
}
h4[data-v-8212892e] {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}
h4[data-v-8212892e] {
    font-size: calc(1.275rem + .3vw);
}
@media (min-width: 1200px) {
h4[data-v-8212892e] {
        font-size: 1.5rem;
}
}
h4[data-v-8212892e] {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
}
.text-center[data-v-8212892e] {
    text-align: center !important;
}
.login-form .login-title[data-v-8212892e] {
    color: #000000;
    color: var(--bg-primary);
    font-size: 22px;
}
.login-form form[data-v-8212892e] {
    margin-top: 10px;
}
img[data-v-8212892e] {
    vertical-align: middle;
}
.login-box img[data-v-8212892e] {
    max-height: 90px;
    max-width: 100%;
}
.fas[data-v-8212892e] {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}
.fas[data-v-8212892e] {
    font-family: "Font Awesome 5 Free";
}
.fas[data-v-8212892e] {
    font-weight: 900;
}
.fa-hand-point-down[data-v-8212892e]:before {
    content: "\f0a7";
}
.input-group[data-v-8212892e] {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.position-relative[data-v-8212892e] {
    position: relative !important;
}
.mb-4[data-v-8212892e] {
    margin-bottom: 1.5rem !important;
}
.d-grid[data-v-8212892e] {
    display: grid !important;
}
small[data-v-8212892e] {
    font-size: .875em;
}
small[data-v-8212892e] {
    font-size: 12px;
}
.mt-1[data-v-8212892e] {
    margin-top: .25rem !important;
}
.login-form form small[data-v-8212892e] {
    font-size: 10px;
    display: inline-block;
}
p[data-v-8212892e] {
    margin-top: 0;
    margin-bottom: 1rem;
}
p[data-v-8212892e] {
    margin-bottom: 0;
    font-size: 16px;
}
.footer[data-v-8212892e] {
    background-color: #000000;
    background-color: var(--bg-primary);
    color: #FFFFFF;
    color: var(--text-primary);
    padding: 4px 8px;
}
.footer.footer-login[data-v-8212892e] {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
}
input[data-v-8212892e] {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
.form-control[data-v-8212892e] {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.form-control[data-v-8212892e] {
    background-color: #ffffff !important;
    background-color: var(--bg-body) !important;
    color: #000000 !important;
    color: var(--text-body) !important;
    border-color: #dbdbdb !important;
    border-color: var(--input-border) !important;
    padding: 5px;
    border-radius: 0;
}
.input-group>.form-control[data-v-8212892e] {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}
.input-group[data-v-8212892e]:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group-text[data-v-8212892e] {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
.input-group[data-v-8212892e]> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
button[data-v-8212892e] {
    border-radius: 0;
}
button[data-v-8212892e] {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button[data-v-8212892e] {
    text-transform: none;
}
[type="submit"][data-v-8212892e],
button[data-v-8212892e] {
    -webkit-appearance: button;
}
.btn[data-v-8212892e] {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.btn-primary[data-v-8212892e] {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}
.btn[data-v-8212892e] {
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    border-radius: 0;
}
.btn-primary[data-v-8212892e] {
    background-color: #000000;
    background-color: var(--bg-primary);
    color: #FFFFFF;
    color: var(--text-primary);
    border-color: #000000;
    border-color: var(--bg-primary);
}
[type="submit"][data-v-8212892e]:not(:disabled),
button[data-v-8212892e]:not(:disabled) {
    cursor: pointer;
}
.btn[data-v-8212892e]:hover {
    color: #212529;
}
.btn-primary[data-v-8212892e]:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
}
.btn[data-v-8212892e]:hover,
.btn[data-v-8212892e]:active {
    opacity: 0.85;
}
.btn-primary[data-v-8212892e]:hover,
.btn-primary[data-v-8212892e]:active {
    background-color: #000000;
    background-color: var(--bg-primary);
    color: #FFFFFF;
    color: var(--text-primary);
    border-color: #000000;
    border-color: var(--bg-primary);
}
[type="button"][data-v-8212892e],
button[data-v-8212892e] {
    -webkit-appearance: button;
}
.mt-2[data-v-8212892e] {
    margin-top: .5rem !important;
}
[type="button"][data-v-8212892e]:not(:disabled),
button[data-v-8212892e]:not(:disabled) {
    cursor: pointer;
}
.footer-top[data-v-8212892e] {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.grecaptcha-badge[data-v-8212892e] {
    visibility: hidden;
}
iframe[data-v-8212892e] {
    border: 0;
}
.fa-user[data-v-8212892e]:before {
    content: "\f007";
}
.fa-key[data-v-8212892e]:before {
    content: "\f084";
}
.float-end[data-v-8212892e] {
    float: right !important;
}
.fa-sign-in-alt[data-v-8212892e]:before {
    content: "\f2f6";
}
.footer-links[data-v-8212892e] {
    flex: 1 1;
}
.support-detail[data-v-8212892e] {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    flex: 1 1;
}
.social-icons-box[data-v-8212892e] {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
    justify-content: flex-end;
}
textarea[data-v-8212892e] {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
textarea[data-v-8212892e] {
    resize: vertical;
}
.navbar[data-v-8212892e] {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: .5rem;
    padding-bottom: .5rem;
}
@media (min-width: 576px) {
.navbar-expand-sm[data-v-8212892e] {
        flex-wrap: nowrap;
        justify-content: flex-start;
}
}
.navbar[data-v-8212892e] {
    padding: 0;
}
h2[data-v-8212892e] {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}
h2[data-v-8212892e] {
    font-size: calc(1.325rem + .9vw);
}
@media (min-width: 1200px) {
h2[data-v-8212892e] {
        font-size: 2rem;
}
}
h2[data-v-8212892e] {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0;
}
ul[data-v-8212892e] {
    padding-left: 2rem;
}
ul[data-v-8212892e] {
    margin-top: 0;
    margin-bottom: 1rem;
}
ul[data-v-8212892e] {
    margin-bottom: 0;
    padding: 0;
}
.navbar-nav[data-v-8212892e] {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
@media (min-width: 576px) {
.navbar-expand-sm .navbar-nav[data-v-8212892e] {
        flex-direction: row;
}
}
ul li[data-v-8212892e] {
    list-style: none;
}
.nav-link[data-v-8212892e] {
    display: block;
    padding: .5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}
.navbar-nav .nav-link[data-v-8212892e] {
    padding-right: 0;
    padding-left: 0;
}
.footer-links .nav-link[data-v-8212892e] {
    color: #FFFFFF;
    color: var(--text-primary);
    font-weight: bold;
    font-size: 16px;
}
@media (min-width: 576px) {
.navbar-expand-sm .navbar-nav .nav-link[data-v-8212892e] {
        padding-right: .5rem;
        padding-left: .5rem;
}
}
.nav-link[data-v-8212892e]:hover {
    color: #0a58ca;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-5mgwV.style-5mgwV[data-v-8212892e] {
    width: 256px;
    height: 60px;
    display: block;
    transition: right 0.3s;
    position: fixed;
    bottom: 14px;
    right: -186px;
    box-shadow: gray 0px 0px 5px;
    border-radius: 2px;
    overflow: hidden;
}
#g-recaptcha-response.style-7RJFo[data-v-8212892e] {
    width: 250px;
    height: 40px;
    border: 1px solid rgb(193, 193, 193);
    margin: 10px 25px;
    padding: 0px;
    resize: none;
    display: none;
}
#style-nTbPa.style-nTbPa[data-v-8212892e] {
    display: none;
}