.flip-vertical-right {
    animation: flip-vertical-right 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate both;
}

:root {
    --bg-primary: #000000;
    --bg-primary90: #000000E6;
    --text-primary: #FFFFFF;
    --bg-secondary: rgb(34, 184, 189);
    --bg-secondary70: #FFC629B3;
    --bg-secondary85: #FFC629D9;
    --text-secondary: #000000;

}

/**
 * ----------------------------------------
 * animation flip-vertical-right
 * ----------------------------------------
 */

@keyframes flip-vertical-right {
    0% {
        transform: rotateY(0);
    }

    100% {
        transform: rotateY(180deg);
    }
}